<template>
  <Breadcrumb :urls="urls"  :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
  <WhatsAppButton></WhatsAppButton>
  <div class="account-info-sec" v-if="translationloaded==true">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div id="show-hidden-menu" class="dashBoarsBtnMain" @click="toggledashboard()">
            <i class="fa fa-th-large" aria-hidden="true"></i>{{translate('dashboard')}}
          </div>
          <div id ="vdasboard"   class="hidden-menu" style="display: none;">
             <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
          <div class="mainSideDiplay">
             <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
        </div>
        <div class="col-md-9">
          <div class="account-details-sec contactDeatilSecmain">
            <h3> {{translate('contactUS')}}</h3>
             <template v-for="(data,index) in fetchCompanyPolicyData" :key="index">
               <template v-if="data.stName=='contactUs' &&  this.lang!='ar'">
                 <div class="mt-5"  v-html="data.stValue"></div>
                
               </template>
                <template v-else-if="data.stName=='contactUsAr' &&  this.lang=='ar'">
                        <div class="mt-5"  v-html="data.stValue"></div>
                </template>

            </template>
        
           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import SideBar from "@/components/my_account/SideBar";
import WhatsAppButton from "@/components/WhatsAppButton";
import helper from "@/helper/helper";
import {mapGetters} from 'vuex';
export default {
  name: "ContactUs",
  components: {
    Breadcrumb,
    SideBar,
    WhatsAppButton
  },
   props:[
     'translationloaded',
     'translate'
  ],
  data() {
    return {
      lang:'',
      urls: [
        {
          path: "/",
          name: "Home",
        },

        {
          path: "/contact-us",
          name: "contactUs",
        },
      ],
    };
  },
  mounted() {
    this.lang = localStorage.getItem('lang');
    helper.backtoTop();
  },
   computed:{
     ...mapGetters(['fetchCompanyPolicyData']),
  },methods:{
     toggledashboard()
    {
       var x = document.getElementById("vdasboard");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    }
  }
};
</script>
<style scoped></style>
